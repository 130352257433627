import React, { useState } from 'react';
import logo from './emortgage.png';
import backgroundImage from './3.png';
import './App.css';
import emailjs from '@emailjs/browser'

function App() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    termsAccepted: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (submitted) {
      validateField(name, value);
    }
  };

  const validateField = (fieldName, value) => {
    let errorMessage = '';
    if (fieldName === 'email') {
      const isValid = /\S+@\S+\.\S+/.test(value);
      errorMessage = isValid ? '' : 'Please enter a valid email address.';
    } else if (fieldName === 'phone') {
      const isValid = /^\d{10}$/.test(value);
      errorMessage = isValid ? '' : 'Please enter a valid 10-digit phone number (format: "2134567890").';
    } else if (fieldName === 'firstName' || fieldName === 'lastName') {
      errorMessage = value.trim() !== '' ? '' : `Please enter your ${fieldName === 'firstName' ? 'first' : 'last'} name.`;
    } else if (fieldName === 'termsAccepted') {
      errorMessage = value ? '' : 'Please accept the terms and conditions.';
    }
    setErrors(prevState => ({
      ...prevState,
      [fieldName]: errorMessage
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { email, phone, firstName, lastName, termsAccepted } = formData;
    validateField('email', email);
    validateField('phone', phone);
    validateField('firstName', firstName);
    validateField('lastName', lastName);

    // Perform validation for termsAccepted only if it's submitted
    if (submitted) {
      validateField('termsAccepted', termsAccepted);
    }

    if (errors.email || errors.phone || errors.firstName || errors.lastName || errors.termsAccepted) {
      console.log('Form submission failed. Please fix validation errors.');
      return;
    } else {
      console.log(formData); // Form submission when validation passes
      const form = document.getElementById('contact-form');
      alert('Form submitted successfully!');
      emailjs.sendForm('service_3wrgzeh', 'template_r4g6h5h', form, 'iKBUzVEQuf_xX5p4l')
        .then((result) => {
          console.log('Email sent successfully:', result.text);
        })
        .catch((error) => {
          console.error('Error sending email:', error);
        });
    }
  };


  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
      </header>

      <div className='Title'>
        <div className='BackgroundImage' style={{ backgroundImage: `url(${backgroundImage})` }}></div>
        <div className='Text'>
          <p>New Program for those looking to Purchase or Refinance a Home</p>
        </div>
      </div>

      <div className='Form-Title'>
        <p className='Form-Title1'>E Mortgage simplifies applying for a loan</p>
        <p className='Form-Title2'>Find a loan that's right for your family and goals</p>
      </div>

      <div className="form-container">
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" />
            {submitted && errors.firstName && <p className="error-message">{errors.firstName}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" />
            {submitted && errors.lastName && <p className="error-message">{errors.lastName}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
            {submitted && errors.email && <p className="error-message">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" />
            {submitted && errors.phone && <p className="error-message">{errors.phone}</p>}
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
              />
              <span className="terms-link">
                I accept the{" "}
                <a href="https://www.emortgagecapital.com/terms" target="_blank" rel="noopener noreferrer">terms & conditions</a>{" "}
                provided by the company. By providing my phone number, I agree to receive text messages from the business.
              </span>
            </label>
            {submitted && errors.termsAccepted && (
              <p className="error-message">{errors.termsAccepted}</p>
            )}
          </div>

          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>

      <div className="contact-info">
        <span className='info1'><i className="fas fa-phone"></i> Call/Text: 949-222-2293</span>
        <span className='info2'><i className="fas fa-envelope"></i> Email: dpark@emortgagecapital.com</span>
      </div>

      <footer className="App-footer">
        All rights reserved. Copyright 2024.
      </footer>

    </div>
  );
}

export default App;
